import axios from 'axios';
import { logout } from 'components/common/logout';
import { toast } from 'react-toastify';

// Create an instance of Axios with custom configuration
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST // Your API base URL
});

// Add an interceptor to the instance to set the token
axiosInstance.interceptors.request.use(config => {
  // Retrieve the token from where you store it (e.g., localStorage, Redux state)
  const token = localStorage.getItem('accessToken'); // Replace with your method to get the token

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

// Add an interceptor to handle response errors, e.g., 401 (Unauthorized)
axiosInstance.interceptors.response.use(
  response => {
    // If the response status is OK (2xx), return the response as is
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      // Handle 401 Unauthorized response here
      if (error.response.data == 'AppNotInstalled') {
        logout();
        window.location.replace(
          'https://github.com/marketplace/gitlights-analytics'
        );
      } else if (error.response.data == 'OutOfFreeTrial') {
        window.location.replace('/plan?plan=out-of-free-trial');
      } else {
        logout();
      }
      // You can also use React Router for navigation if your app uses it
      // history.push('/'); // Example if using React Router
    }
    if (error.response && error.response.status === 402) {
      toast.warning(
        'This request is not available with the current plan. Go to the plans section for more information.',
        { position: 'bottom-right' }
      );
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
