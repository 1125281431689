import Section from 'components/common/Section';
import PricingDefault from 'components/pages/pricing/pricing-default/PricingDefault';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Cta from './Cta';
import FooterStandard from './FooterStandard';
import Hero from './Hero';
import NavbarStandard from './NavbarStandard';
import Features from './Processes';
import Services from './Services';

const Landing = () => {
  return (
    <>
      <NavbarStandard />
      <Hero />
      {/* <Partners /> */}
      <div id="features">
        <Features />
      </div>
      <div id="get_started">
        <Services />
      </div>
      {/* <Testimonial /> */}
      <Cta />
      <Section bg="light" className="text-center">
        <div id="pricing" className="justify-content-center text-center row">
          <div className="col-lg-8">
            <h1 className="fs-2 fs-sm-4 fs-md-5">
              Select your plan after 15-day free trial
            </h1>
            <p className="lead">
              During the free trial period, you will have access to the Base
              Plan for 15 days at no cost. Your data will be updated daily.
              Additionally, we will initially load the last 7 days of
              organization's activity on GitHub. Below are Gitlights' plans for
              continuing to use the application once the trial period has ended.
              There are three monthly subscription plans: Base, Advanced and
              Advanced and Enterprise Plans. Additionally, you have the option
              to purchase part of your historical data with the 'Load Historical
              Data' plan. By purchasing historical data, you acquire 5000 GitHub
              credits to access their API.
            </p>
          </div>
        </div>

        <Row>
          <Col lg={1}></Col>
          <Col lg={10}>
            <PricingDefault isPurchasable={false} />
          </Col>
          <Col lg={1}></Col>
        </Row>
      </Section>
      <FooterStandard />
    </>
  );
};

export default Landing;
