import { version } from 'config';
import packageInfo from '../../package.json';

const { homepage } = packageInfo;

export const faqs = [
  {
    id: 0,
    title: `What is the purpose of Gitlights?`,
    description: `Gitlights is a trusted application that connects to the GitHub API to obtain relevant metrics and key performance indicators (KPIs) for development performance. Our aim is to provide valuable insights to enhance your team's productivity and efficiency.`
  },
  {
    id: 1,
    title: 'How does Gitlights prioritize data privacy?',
    description: `At Gitlights, we prioritize data privacy and security. We strictly use the data we collect from your GitHub account solely for the purpose of generating performance metrics. We do not share this information with any third parties and ensure it is securely stored on our servers using industry-standard security measures.`
  },
  {
    id: 2,
    title: 'Does Gitlights have access to our source code?',
    description: `No, Gitlights does not have access to or read your source code. We respect the confidentiality of your intellectual property. Our focus is on analyzing metadata such as commits, branches, pull requests, comments, reviews, and pull request states to provide performance insights without accessing your source code.`
  },
  {
    id: 3,
    title: `What data does Gitlights access through the GitHub API?`,
    description: `Gitlights accesses specific data through the GitHub API, including commit history, branch details, pull request information, comments, code reviews, and pull request statuses. This information is essential for generating accurate KPIs related to your development processes and is handled securely within our platform.`
  },
  {
    id: 5,
    title: `Can we revoke permissions or disconnect our GitHub account from Gitlights?`,
    description: `Yes, you have complete control over the permissions granted to Gitlights. If at any time you decide to revoke access or disconnect your GitHub account from our app, you can do so effortlessly. This ensures that Gitlights will no longer retrieve any data from your GitHub repositories.`
  },
  {
    id: 6,
    title: `How can we trust Gitlights and receive support if needed?`,
    description: `Gitlights is committed to building and maintaining trust with our customers. Should you require any assistance or have any concerns, our dedicated support team is readily available to address your questions and provide prompt support.`
  },
  {
    id: 7,
    title: `Can I remove my Gitlights account and what happens to my data?`,
    description: `Absolutely! You have the option to remove your Gitlights account at any time. When you initiate the account removal process, all your historical data stored on our servers will be permanently deleted. We prioritize data privacy and ensure that your information is completely wiped from our system upon account removal.`
  },
  {
    id: 8,
    title: ` What happens to my subscription if I remove my Gitlights account?`,
    description: `When you remove your Gitlights account, your subscription will be canceled without any extra charges. We believe in providing a seamless experience, which includes the flexibility to manage your subscription according to your needs. You can rest assured that no additional fees will be incurred once your account is removed.`
  }
];

export const faqsGeneral = [
  {
    id: 0,
    title: `What is Gitlights, and how can it benefit my development team?`,
    description: `Gitlights is a SaaS platform designed to provide in-depth metrics and analytics on development team performance, helping improve efficiency, collaboration, and a better understanding of software investment.`
  },
  {
    id: 1,
    title: `What features does Gitlights offer for tracking and analyzing our development data?`,
    description: `Gitlights provides comprehensive monitoring of commits, pull requests, developer performance scores, commit categorization to understand investment balance, code quality analysis, and AI-driven automated reviews, along with other features based on your selected plan.`
  },
  {
    id: 2,
    title: `What are the differences between Gitlights' plans?`,
    description: `Our plans offer different levels of functionality, from essential performance metrics to advanced analyses with generative AI and collaboration options for sharing platform access with other team members.`
  }
];
export const faqsPlans = [
  {
    id: 0,
    title: `What does the Gitlights Base plan include?`,
    description: `The Base plan provides core features such as commit monitoring, pull request analysis, investment balance tracking, and developer performance scores.`
  },
  {
    id: 1,
    title: `What additional features does the Advanced plan offer compared to the Base plan?`,
    description: `The Advanced plan includes all functionalities of the Base plan and adds automated reviews with generative AI, email and Slack notifications, and the ability to share data access with up to 5 users.`
  },
  {
    id: 2,
    title: `What additional features does the Enterprise plan offer compared to the Advanced plan?`,
    description: `Functionally, they are similar; however, the Advanced plan allows data sharing with up to 5 users, while the Enterprise plan enables data sharing with up to 10 users.`
  },
  {
    id: 3,
    title: `What advantages does the Enterprise plan offer for large teams?`,
    description: `The Enterprise plan enhances collaboration, allowing shared access for up to 10 users, ideal for larger teams needing shared performance insights. Each user can focus on a specific team, repository, squad, or developer group for tailored analysis.`
  }
];

export const faqsHistoricalLoad = [
  {
    id: 0,
    title: `What is the historical data package in Gitlights?`,
    description: `This package allows you to access the Base plan functionalities to analyze your GitHub historical data without daily updates.`
  },
  {
    id: 1,
    title: `If I purchase the historical data package, can I update the data daily?`,
    description: `No, the historical data package allows analysis only on the initially loaded data, with no daily updates.`
  },
  {
    id: 2,
    title: `How much data is loaded in the historical package?`,
    description: `The historical data loading process retrieves data through 12,500 to 15,000 GitHub API calls, covering up to a maximum of one year. The time range depends on your organization's GitHub activity (commits, pull requests, comments, repositories, etc.).`
  },
  {
    id: 3,
    title: `What Gitlights functionalities apply to the loaded historical data?`,
    description: `You can use the Base plan features to analyze and gain insights from the loaded historical data.`
  },
  {
    id: 4,
    title: `Can I subscribe to a plan for daily data updates after purchasing a historical data package?`,
    description: `Yes, absolutely. By subscribing to a plan, your GitHub data will be updated daily, and you will retain access to the historical data package.`
  },
  {
    id: 5,
    title: `If I already have an active subscription, can I purchase a historical data package?`,
    description: `Yes, historical data can be added to your account alongside the data already updated daily through your subscription plan.`
  },
  {
    id: 6,
    title: `Can I purchase the historical data package more than once?`,
    description: `No, once a historical data load has been completed, further historical data loads cannot be performed.`
  }
];

export const faqsFreeTrial = [
  {
    id: 0,
    title: `How does the Gitlights free trial period work?`,
    description: `When you install Gitlights, a 15-day free trial of the Base plan is automatically activated, allowing daily updates during this period. If no plan is subscribed to by the end, access and daily data loading are deactivated.`
  },
  {
    id: 1,
    title: `How long does the trial period last, and what features does it include?`,
    description: `The trial lasts 15 days and includes all functionalities of the Base plan with daily updates.`
  },
  {
    id: 2,
    title: `During the trial period, are my data updated daily?`,
    description: `Yes, data are updated daily so you can explore all the features of the Base plan.`
  },
  {
    id: 3,
    title: `Can I try Gitlights' advanced features during the trial period?`,
    description: `No, the trial period includes only the features of the Base plan.`
  }
];
export const faqsNotifications = [
  {
    id: 0,
    title: `What types of notifications can I receive in Gitlights?`,
    description: `Gitlights allows you to set up notifications for weekly and monthly reports generated by our innovative AI system.`
  },
  {
    id: 1,
    title: `How do I set up email and Slack notifications in Gitlights?`,
    description: `Email and Slack notifications are available in the Advanced and Enterprise plans and can be configured in the settings panel.`
  },
  {
    id: 2,
    title: `Are notifications available in all Gitlights plans?`,
    description: `No, advanced notifications are only available in the Advanced and Enterprise plans.`
  }
];
export const faqsUsersAccess = [
  {
    id: 0,
    title: `How many users can view and manage data in each Gitlights plan?`,
    description: `In the Base plan, only one user has access; the Advanced plan allows data sharing with up to 5 users, and the Enterprise plan with up to 10 users.`
  },
  {
    id: 1,
    title: `Can I add or remove users who have access to data in Gitlights?`,
    description: `Yes, you can manage user permissions in the Advanced and Enterprise plans from the "My Account" section.`
  },
  {
    id: 2,
    title: `What permissions do additional users have to view and use shared data?`,
    description: `Additional users can view and analyze data across all dashboards without restriction, but they cannot grant access to others.`
  }
];
export const faqsDataQuality = [
  {
    id: 0,
    title: `Where does Gitlights get the data it monitors?`,
    description: `Gitlights pulls data directly from GitHub's private API with your consent. For added security, we refresh the token generated for your GitHub account every few hours. We never store client tokens in the database; they are requested and used only on demand.`
  },
  {
    id: 1,
    title: `How does Gitlights ensure the accuracy and quality of the data it displays?`,
    description: `Gitlights constantly syncs with your repositories through the GitHub API and uses verification algorithms to maintain data accuracy and reliability.`
  },
  {
    id: 2,
    title: `Can I trust the accuracy of the data and metrics generated by Gitlights?`,
    description: `Yes, Gitlights employs verification and synchronization processes to ensure the accuracy of all metrics. Periodic testing routines are also run to ensure stability from release to release.`
  },
  {
    id: 3,
    title: `Is it possible for some data to fail to sync or experience delays? Why would this happen?`,
    description: `Occasional delays may occur due to connection issues with repositories. However, Gitlights monitors these incidents and ensures synchronization once the connection is restored.`
  },
  {
    id: 4,
    title: `Does Gitlights store my data on its servers, or does it only display it?`,
    description: `Gitlights stores only derived metrics and analyses, not complete code or sensitive repository data.`
  }
];
export const faqsDataSync = [
  {
    id: 0,
    title: `How often is data updated in Gitlights?`,
    description: `In subscription plans, data is updated at least once every 48 hours, with an average update frequency of once per day. The historical data package does not include updates.`
  },
  {
    id: 1,
    title: `Is data updated in real-time or at specific intervals?`,
    description: `Data is updated at specific intervals and never in real-time from the source.`
  },
  {
    id: 2,
    title: `How can I see the last data update date in Gitlights?`,
    description: `You can view the date and time of the last data update on the Gitlights dashboard.`
  },
  {
    id: 3,
    title: `Does the update process use my system’s resources, or is it entirely independent?`,
    description: `The update process is independent, as Gitlights pulls data directly from repositories and processes it on its own servers.`
  },
  {
    id: 4,
    title: `How is daily data updating managed during the trial period?`,
    description: `During the trial period, Gitlights updates data daily, as it does in subscription plans.`
  },
  {
    id: 5,
    title: `In which plans is daily data updating available?`,
    description: `Daily updates are available in the Base, Advanced, and Enterprise plans.`
  },
  {
    id: 6,
    title: `Can I adjust the data update frequency in Gitlights?`,
    description: `Currently, the update frequency is not adjustable and remains daily in the main plans.`
  }
];

export const faqsDataAge = [
  {
    id: 0,
    title: `How long does Gitlights store historical data?`,
    description: `Gitlights stores historical data based on the chosen subscription plan and the historical data package.`
  },
  {
    id: 1,
    title: `How are my team's historical data reflected in the metrics?`,
    description: `Historical data enable trend analysis and allow you to visualize performance over time.`
  },
  {
    id: 2,
    title: `What happens to my data if I cancel my Gitlights subscription?`,
    description: `If you cancel, you lose access to stored data and generated metrics. Data is permanently deleted from our servers 15 days after cancellation.`
  },
  {
    id: 3,
    title: `Can I export my historical data if I no longer want to use Gitlights?`,
    description: `No, data cannot be exported before canceling the subscription.`
  },
  {
    id: 4,
    title: `Are historical data automatically kept up-to-date, or are they loaded only once?`,
    description: `Historical data are loaded only once and are not updated daily.`
  }
];
export const faqsSecurity = [
  {
    id: 0,
    title: `How does Gitlights ensure the security of the data it handles?`,
    description: `Gitlights uses encryption and advanced security systems to protect all data. The workers responsible for data loading only have access to GitHub servers and cannot receive incoming connections. User sessions are secured by GitHub OAuth tokens.`
  },
  {
    id: 1,
    title: `Does Gitlights have access to all the code in my repository or just the metrics?`,
    description: `Gitlights only accesses the necessary aggregated metrics, not the complete repository code.`
  },
  {
    id: 2,
    title: `Are data transmitted securely between my repository and Gitlights?`,
    description: `Yes, Gitlights secures all data transmissions using encrypted protocols. Connections to the GitHub API are made via HTTPS and with on-demand tokens generated for each request.`
  },
  {
    id: 3,
    title: `Does Gitlights store sensitive or confidential information about my team?`,
    description: `No, it only stores metrics and analyses derived from your repository data.`
  },
  {
    id: 4,
    title: `What measures does Gitlights have to protect my information from unauthorized access?`,
    description: `Gitlights implements user authentication and strict security measures within its infrastructure.`
  }
];
export const faqsTeamPerformance = [
  {
    id: 0,
    title: `How can Gitlights help improve my development team's productivity?`,
    description: `Gitlights provides metrics that help identify areas for improvement, optimize processes, understand investment balance in software types, and enhance team collaboration. These metrics allow for deeper analysis and more informed, data-driven decisions to improve team productivity.`
  },
  {
    id: 1,
    title: `Can Gitlights help me identify areas for improvement in my development workflow?`,
    description: `Yes, Gitlights displays efficiency and performance metrics, highlighting bottlenecks and stages in the workflow that could be optimized, such as prolonged review times or delays in integration. It also provides insights into investment balance, helping you understand if your team is more focused on bug fixing and maintenance or new development.`
  },
  {
    id: 2,
    title: `What benefits does Gitlights bring to collaboration among development team members?`,
    description: `Gitlights promotes transparency in project performance and status, fostering better communication and collaboration within the team. Shared data enables everyone to work with the same information and objectives.`
  },
  {
    id: 3,
    title: `Can I use Gitlights to track the progress of a specific project?`,
    description: `Yes, Gitlights allows you to monitor individual projects by filtering by repository, dates, or developers linked to the project. It provides detailed metrics on progress and performance, helping you track the project from planning through to delivery.`
  },
  {
    id: 4,
    title: `How do generative AI reviews in the Advanced plan improve code quality?`,
    description: `Automated reviews with generative AI provide a comprehensive overview of team performance. They are useful for project managers who want a quick, high-level summary of development teams without needing to dive into a detailed analysis. Additionally, the reviews offer specific improvement ideas and plans by project or developer, with weekly and monthly summaries.`
  }
];

export const faqsCancellation = [
  {
    id: 0,
    title: `How can I cancel my subscription?`,
    description: `To cancel your subscription, go to the “Plans” section and then to “Manage my subscription.” From there, you can cancel your plan. No further charges will be billed after cancellation. Your account and session will remain active for the rest of the current month, and at the end of the month, it will be deactivated. Data will be retained for up to 30 days after cancellation and then deleted. You can always return to the Plans section to subscribe again or purchase historical data. Please note, canceling a subscription does not uninstall the Gitlights app from your GitHub account.`
  },
  {
    id: 1,
    title: `How can I uninstall Gitlights?`,
    description: `To uninstall Gitlights, go to your GitHub account and remove the application from the “My Apps” section. Once uninstalled, Gitlights will lose permission to access your GitHub data, and your Gitlights account will be deleted. If an active paid subscription exists at the time of uninstallation, it will be deactivated, and no further charges will be applied.`
  },
  {
    id: 2,
    title: `When are my data deleted from Gitlights?`,
    description: `Data are deleted when the Gitlights app is uninstalled from GitHub or 30 days after a free trial or subscription cancellation. You can also request data deletion at any time by contacting support at support@gitlights.com.`
  },
  {
    id: 3,
    title: `How can I add my tax ID and download invoices?`,
    description: `In the “Plans” section, under “Manage my subscription,” you can access and edit tax information and download invoices for each Gitlights payment.`
  },
  {
    id: 4,
    title: `Can I switch from one subscription plan to another?`,
    description: `Yes, you can switch plans in the “Plans” section under “Manage my subscription.” Upgrades and downgrades are both available.`
  },
  {
    id: 5,
    title: `How can I change my payment method?`,
    description: `You can change your payment method in the “Plans” section under “Manage my subscription.”`
  }
];

export const faqAccordions = [
  {
    id: 0,
    title: 'How long do payouts take?',
    description:
      '<p class = "mb-0">Once you’re set up, payouts arrive in your bank account on a 2-day rolling basis. Or you can opt to receive payouts weekly or monthly.</p>'
  },
  {
    id: 1,
    title: 'How do refunds work?',
    description:
      '<p class = "mb-0">You can issue either partial or full refunds. There are no fees to refund a charge, but the fees from the original charge are not returned.</p>'
  },
  {
    id: 2,
    title: 'How much do disputes costs?',
    description:
      '<p class = "mb-0">Disputed payments (also known as chargebacks) incur a $15.00 fee. If the customer’s bank resolves the dispute in your favor, the fee is fully refunded.</p>'
  },
  {
    id: 3,
    title: 'Is there a fee to use Apple Pay or Google Pay?',
    description:
      '<p class = "mb-0">There are no additional fees for using our mobile SDKs or to accept payments using consumer wallets like Apple Pay or Google Pay.</p>'
  }
];

export const faqDoc = [
  {
    id: 1,
    title: 'How to start working with Falcon React',
    description: `<p class='mb-0'>Install latest version of Node js to your PC <a href='https://nodejs.dev/' target="_blank">https://nodejs.dev/</a> Download the template file and unzip it.
    You will get 2 files in the folder. Now unzip the <code>falcon-react-${version}</code> directory and open this directory from your terminal.
    Run <code>npm install </code> from your terminal.
    After installing all the required packages, run <code>npm start</code>. <br>
    Please check the following link to see How to Getting started in Falcon React: <a target='_blank' href='//${homepage}/documentation/getting-started'>${homepage}/documentation/getting-started<a/>.</p>`
  },
  {
    id: 2,
    title:
      'Is there a specific version of Node.js I should use for Falcon React?',
    description: `<p class="mb-0">Node version to <code>16.15.1</code> or any further updated version.</p>`
  },

  {
    id: 3,
    title: 'What React library is used for general API integration?',
    description: `<p class="mb-0">We used react context API for general API integration.</p>`
  },
  {
    id: 4,
    title: 'How are the SEO headers built?',
    description: `<p class="mb-0">We didn't build with any SEO headers by default. You have to integrate it manually by yourself.</p>`
  },
  {
    id: 5,
    title:
      'Which bootstrap react framework you are using? Reactstrap or React-Bootstrap?',
    description: `<p class="mb-0">We used the <b>reactstrap</b> for Falcon React <code>v2.x</code> and <b>React bootstrap</b> for Falcon React <code>v3.x</code>.</p>`
  },
  {
    id: 6,
    title: 'Where will I get the Figma Files?',
    description: `<p >You will find the Figma link in the <code>README.md</code> file on your downloaded copy of Falcon. Here is the detailed documentation: <a target='_blank' href='//${homepage}/documentation/design-file'> ${homepage}/documentation/design-file</a>.</p>
    <p class='mb-0'>If you still have any problem with the link, reach us out with your purchase ID at <a href="mailto:support@themewagon.com">support@themewagon.com</a></p>`
  },
  {
    id: 7,
    title:
      'What are the bare minimum files just to get the layout and navigation?',
    description: `<p >You can start from the Falcon’s Starter page where the minimum required components are included to get layout and navigation.
    More specifically, the below list of files are required to work with layout and navigation:<br>
    <code>src/assets</code><br>
    <code>src/components/common</code><br>
    <code>src/components/footer</code><br>
    <code>src/components/navbar</code><br>
    <code>src/components/notification</code><br>
    <code>src/components/pages/Starter.js</code><br>
    <code>src/components/settings-panel</code><br>
    <code>src/context</code><br>
    <code>src/data</code><br>
    <code>src/helpers</code><br>
    <code>src/hooks</code><br>
    <code>src/layouts/Layout.js</code><br>
    <code>src/layouts/MainLayout.js</code><br>
    <code>src/layouts/MainRoutes.js</code><br>
    <code>src/reducers</code><br>
    <code>src/routes</code><br>
    <code>src/App.js</code><br>
    <code>src/Config.js</code><br>
    <code>src/Index.js</code><br>
    <code>src/Main.js</code></p>

    <p>Note:  Remember deleting other files/directories will be the cause of errors. You have to remove the corresponding import statement and unnecessary lines from your code manually.
    </p>
    <p class="mb-0">We have created a mini version of Falcon React with all the essential files only. If you are interested, please reach us out with your purchase ID at <a href="mailto:support@themewagon.com">support@themewagon.com</a>.
    </p>`
  },
  {
    id: 8,
    title: 'How to compile SCSS files with Falcon React?',
    description: `<p class="mb-0">To compile scss files in Falcon react run npm run <code>build:css</code> from your terminal, or you can watch the scss files by running npm run <code>watch:css</code> to a separate terminal. 
    Check the link below to have a look on the styling documentation in Falcon React <a target='_blank' href='//${homepage}/documentation/styling'> ${homepage}/documentation/styling</a>
    </p>`
  },
  {
    id: 9,
    title: 'How to update from Falcon React v2.x to the upper version?',
    description: `<p><b>Updating from any earlier version of 3.x to ${version}:</b></br>
    Check out the following link and follow the changes: <a target='_blank' href='//${homepage}/changelog'> ${homepage}/changelog</a>
    </p>
    <p class='mb-0'><b>Updating from version 2 to version 3:</b></br>
    As the latest versions of templates and all their packages have different structures behind their components, you have to update all the components manually into your project.<br>
    So, it would be better to start building applications with the Bootstrap 5 version of Falcon from scratch. You can include any components from your existing project to your current project. Bring the component and its required SCSS and place it into your current project.
    </p>`
  }
];
