import axiosInstance from 'http/axiosConfig';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { checkIsDataLoaded, EmptyPage } from 'emptyPage';

const WeeklyReportsView = ({ period = 'week' }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isDataLoaded] = useState(checkIsDataLoaded());
  const [report, setReport] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await axiosInstance.get('/get-recurring-reports/');

        if (response.data.status === 'OK') {
          let reportContent = '';
          if (period === 'week') {
            reportContent = response.data.recurring_reports.weekly_report;
          } else if (period === 'month') {
            reportContent = response.data.recurring_reports.monthly_report;
          }
          setReport(reportContent);
        } else if (response.data.status === 'KO') {
          setErrorMessage('There is no report yet');
          toast.warning('There is no report yet', {
            position: 'top-right',
            autoClose: 20000
          });
        } else {
          setErrorMessage('An unexpected error occurred');
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 402) {
            setErrorMessage(
              '🚫 Oops! AI reviews aren’t available in your current plan. 👉 Check out the plans section for more information!'
            );
          } else {
            console.log(error.response.data);
            setErrorMessage('An error occurred while fetching data');
            toast.error('An error occurred while fetching data', {
              position: 'top-right',
              autoClose: 20000
            });
          }
        } else if (error.request) {
          console.log(error.request);
          setErrorMessage('No response received from the server');
          toast.error('No response received from the server', {
            position: 'top-right',
            autoClose: 20000
          });
        } else {
          console.log('Error', error.message);
          setErrorMessage('An error occurred while setting up the request');
          toast.error('An error occurred while setting up the request', {
            position: 'top-right',
            autoClose: 20000
          });
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, [period]);

  if (isLoading) {
    return (
      <Spinner
        className="position-absolute start-50"
        animation="border"
        size="xl"
      />
    );
  }

  if (!isDataLoaded) {
    return <EmptyPage />;
  }

  if (errorMessage) {
    return (
      <div>
        <h5>{errorMessage}</h5>
      </div>
    );
  }

  return (
    <Row className="g-3 mb-3">
      <Col md={2}></Col>
      <Col md={8}>
        <div
          className="recurringReportContainer"
          dangerouslySetInnerHTML={{ __html: report }}
        ></div>
      </Col>
      <Col md={2}></Col>
    </Row>
  );
};

WeeklyReportsView.propTypes = {
  period: PropTypes.string
};

export default WeeklyReportsView;
