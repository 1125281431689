import Account from 'components/account-settings/account';
import Settings from 'components/account-settings/settings';
import WeeklyReportsView from 'components/reports/weeklyReports';
import CommitsView from 'components/dashboards/commits';
import Dashboard from 'components/dashboards/default';
import DeveloperDetailView from 'components/dashboards/developers/detail';
import DevelopersView from 'components/dashboards/developers/global';
import RepositoriesView from 'components/dashboards/e-commerce';
import InvestmentBalance from 'components/dashboards/investment-balance';
import PullRequestsView from 'components/dashboards/prs';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import Landing from 'components/pages/landing/Landing';
import LoadHistoricalPage from 'components/pages/loading-data/LoadHistoricalPage';
import SubscriptionPurchaseSuccess from 'components/pages/loading-data/SubscriptionPurchaseSuccess';
import PricingDefault from 'components/pages/pricing/pricing-default/PricingDefault';
import SupportPage from 'components/pages/support/supportPage';
import FaqsPage from 'components/pages/faqs/faqsPage';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ErrorLayout from '../layouts/ErrorLayout';
import MainLayout from '../layouts/MainLayout';
const FalconRoutes = () => {
  return (
    <Routes>
      <Route path="landing" element={<Landing />} />
      <Route path="/" element={<Landing />} />
      <Route path="/load-historical" element={<LoadHistoricalPage />} />
      <Route
        path="/subscription-purchase-success"
        element={<SubscriptionPurchaseSuccess />}
      />
      <Route element={<ErrorLayout />}>
        <Route path="errors/404" element={<Error404 />} />
        <Route path="errors/500" element={<Error500 />} />
      </Route>

      {/* //--- MainLayout Starts  */}

      <Route element={<MainLayout />}>
        {/*Dashboard*/}
        <Route path="/dashboard/main" element={<Dashboard />} />
        <Route path="dashboard/commits" element={<CommitsView />} />
        <Route path="dashboard/prs" element={<PullRequestsView />} />
        <Route
          path="dashboard/investment-balance"
          element={<InvestmentBalance />}
        />
        <Route path="dashboard/developers" element={<DevelopersView />} />
        <Route path="dashboard/developer" element={<DeveloperDetailView />} />
        <Route path="dashboard/e-commerce" element={<RepositoriesView />} />
        <Route path="dashboard/lms" element={<DevelopersView />} />
        <Route path="plan" element={<PricingDefault />} />
        <Route path="account" element={<Account />} />
        <Route path="settings" element={<Settings />} />
        <Route
          path="reports/week"
          element={<WeeklyReportsView period="week" />}
        />
        <Route
          path="reports/month"
          element={<WeeklyReportsView period="month" />}
        />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/faq" element={<FaqsPage />} />
      </Route>

      {/* //--- MainLayout end  */}

      {/* <Navigate to="/errors/404" /> */}
      <Route path="*" element={<Navigate to="/errors/404" replace />} />
    </Routes>
  );
};

export default FalconRoutes;
