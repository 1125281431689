import gitlightsLogo from 'assets/img/gitlights-logo.png';
import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';
const handleGoToDashboard = () => {
  window.location.href = '/dashboard/main';
};
export const LoadHistoricalPage = () => {
  return (
    <>
      <WidgetSectionTitle
        icon="exclamation-circle"
        title="Loading historical data"
        subtitle="Your analytics data will be available shortly"
        transform="shrink-4"
        className="mb-4 mt-6"
      />
      <Row className="g-3 mb-3">
        <Col md={3}></Col>
        <Col md={6}>
          <Card className="text-center">
            <Card.Body className="p-5">
              <div className="display-1 text-300 fs-error join-gitlights-github-img">
                <Row className="g-3 mb-3">
                  <Col md={12}>
                    <img
                      width="150px"
                      height="160px"
                      src={gitlightsLogo}
                      alt="Gitlights Logo"
                    />
                  </Col>
                </Row>
              </div>
              <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
                We are loading your data!!
              </p>
              <hr />
              <p>
                We're busy collecting your historical data to provide you with
                valuable insights. In a few minutes, you will be able to access
                your historical data.
              </p>
            </Card.Body>
          </Card>
          <Row>
            <Col md={4}></Col>
            <Col
              md={4}
              className="goToDashboardButton"
              onClick={handleGoToDashboard}
            >
              <Button>Go to Dashboard</Button>
            </Col>
            <Col md={4}></Col>
          </Row>
        </Col>
        <Col md={3}></Col>
      </Row>
      <Row>
        <Col md={1}></Col>
        <Col md={10}></Col>
        <Col md={1}></Col>
      </Row>
    </>
  );
};
export default LoadHistoricalPage;
