import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import { logout } from 'components/common/logout';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ProfileDropdown = () => {
  var storedUserData = localStorage.getItem('userData');
  var parsedUserData = JSON.parse(storedUserData);

  const user_name = parsedUserData.user_name;
  const user_avatar_url = parsedUserData.user_avatar_url;
  const organization = parsedUserData.organization;

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={user_avatar_url} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item
            as={Link}
            to="/account"
            className="fw-bold text-warning"
            href="#!"
          >
            <FontAwesomeIcon icon="user" className="me-1" />
            <span>{user_name}</span>
          </Dropdown.Item>
          <Dropdown.Item
            as={Link}
            to="/account"
            className="fw-bold text-warning"
            href="#!"
          >
            <FontAwesomeIcon icon="crown" className="me-1" />
            <span>{organization}</span>
          </Dropdown.Item>
          <Dropdown.Divider />

          <Dropdown.Item as={Link} to="/plan">
            Plan
          </Dropdown.Item>

          <Dropdown.Item as={Link} to="/settings">
            Settings
          </Dropdown.Item>

          <Dropdown.Item as={Link} to="/faq">
            FAQ
          </Dropdown.Item>

          <Dropdown.Item as={Link} to="/support">
            Support
          </Dropdown.Item>

          <Dropdown.Divider />
          <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
