import ReactEChartsCore from 'echarts-for-react/lib/core';
import { ToolboxComponent } from 'echarts/components';
import * as echarts from 'echarts/core';
import { getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import { default as React, useRef } from 'react';

echarts.use([ToolboxComponent]);

const getColorForSeries = seriesIndex => {
  const colorNames = [
    'purple',
    'success',
    'pink',
    'orange',
    'info',
    'cyan',
    'danger',
    'blue',
    'light',
    'dark',
    'green',
    'teal',
    'yellow',
    'indigo',
    'secondary',
    'light'
  ];
  if (seriesIndex < colorNames.length) {
    return getColor(colorNames[seriesIndex]);
  } else {
    // Si se necesitan más colores, seleccionar un color primario
    return getColor('primary');
  }
};

const ComboPieMultiLine = ({ dataset }) => {
  const chartRef = useRef(null);

  // Memoizar cálculos para evitar recalcular en cada renderizado
  const { seriesData, investmentTypes, totals, colorMap } =
    React.useMemo(() => {
      const seriesData = dataset.slice(1);
      const investmentTypes = seriesData.map(series => series[0]);
      const totals = seriesData.map(row => {
        const data = row.slice(1);
        return data.reduce((acc, val) => acc + val, 0);
      });

      // Crear un mapeo de tipo de inversión a color
      const colorMap = {};
      investmentTypes.forEach((type, index) => {
        colorMap[type] = getColorForSeries(index);
      });

      return { seriesData, investmentTypes, totals, colorMap };
    }, [dataset]);

  const getOption = () => {
    const series = investmentTypes.map((type, index) => {
      const color = getColorForSeries(index);
      return {
        name: type, // Asegurarse de que el 'name' está establecido
        type: 'bar',
        stack: 'one',
        smooth: true,
        seriesLayoutBy: 'row',
        emphasis: { focus: 'series' },
        symbolSize: 10,
        lineStyle: { color },
        itemStyle: {
          color,
          borderRadius: [3, 3, 0, 0]
        },
        symbol: 'circle'
      };
    });

    // Establecer datos iniciales del gráfico circular con colores
    series.push({
      type: 'pie',
      id: 'pie',
      radius: '30%',
      center: ['50%', '28%'],
      emphasis: { focus: 'data' },
      label: {
        formatter: '{b}: {c} ({d}%)',
        color: getColor('gray-600')
      },
      data: investmentTypes.map((type, index) => {
        return {
          name: type,
          value: totals[index],
          itemStyle: {
            color: colorMap[type]
          }
        };
      })
    });

    // Eliminar la propiedad 'color' del objeto de opciones
    return {
      // color: colorArray, // Eliminado para evitar interferencias
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: getColor('gray-500')
          },
          label: {
            show: true,
            backgroundColor: getColor('gray-600'),
            color: getColor('gray-100')
          }
        },
        padding: [7, 10],
        backgroundColor: getColor('gray-100'),
        borderColor: getColor('gray-300'),
        textStyle: { color: getColor('dark') },
        borderWidth: 1,
        transitionDuration: 0
      },
      toolbox: {
        top: 0,
        feature: {
          dataView: { show: false },
          magicType: {
            show: true,
            type: ['line', 'bar', 'stack', 'tiled']
          },
          restore: { show: true },
          saveAsImage: { show: true }
        },
        iconStyle: {
          borderColor: getColor('gray-700'),
          borderWidth: 1
        },
        emphasis: {
          iconStyle: {
            textFill: getColor('gray-600')
          }
        }
      },
      dataset: {
        source: dataset
      },
      xAxis: {
        type: 'category',
        axisLine: {
          lineStyle: {
            color: getColor('gray-300')
          }
        },
        axisLabel: {
          color: getColor('gray-600')
        },
        axisPointer: {
          lineStyle: {
            color: getColor('gray-300')
          }
        }
      },
      yAxis: {
        gridIndex: 0,
        axisLabel: {
          color: getColor('gray-600')
        },
        splitLine: {
          lineStyle: {
            color: getColor('gray-200')
          }
        }
      },
      series,
      grid: {
        right: 10,
        left: 5,
        bottom: 5,
        top: '55%',
        containLabel: true
      }
    };
  };

  const updateAxisPointer = event => {
    var xAxisInfo = event.axesInfo[0];
    if (xAxisInfo) {
      var dimension = xAxisInfo.value + 1;

      // Para cada tipo de inversión, obtener el valor en la columna 'dimension'
      const pieData = seriesData.map(row => {
        return {
          name: row[0],
          value: row[dimension],
          itemStyle: {
            color: colorMap[row[0]]
          }
        };
      });

      chartRef.current.getEchartsInstance().setOption({
        series: {
          id: 'pie',
          data: pieData
        }
      });
    } else {
      // Restablecer a los totales cuando no se está sobre el gráfico de barras
      const pieData = investmentTypes.map((type, index) => {
        return {
          name: type,
          value: totals[index],
          itemStyle: {
            color: colorMap[type]
          }
        };
      });

      chartRef.current.getEchartsInstance().setOption({
        series: {
          id: 'pie',
          data: pieData
        }
      });
    }
  };

  const onEvents = {
    updateAxisPointer: updateAxisPointer
  };

  return (
    <ReactEChartsCore
      echarts={echarts}
      ref={chartRef}
      option={getOption()}
      style={{ height: '31.25rem' }}
      onEvents={onEvents}
    />
  );
};

ComboPieMultiLine.propTypes = {
  dataset: PropTypes.arrayOf(PropTypes.array)
};

export default ComboPieMultiLine;
