import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';
import { faqsGeneral } from 'data/faqs';
import { faqsPlans } from 'data/faqs';
import { faqsHistoricalLoad } from 'data/faqs';
import { faqsFreeTrial } from 'data/faqs';
import { faqsNotifications } from 'data/faqs';
import { faqsUsersAccess } from 'data/faqs';
import { faqsDataQuality } from 'data/faqs';
import { faqsDataSync } from 'data/faqs';
import { faqsDataAge } from 'data/faqs';
import { faqsSecurity } from 'data/faqs';
import { faqsTeamPerformance } from 'data/faqs';
import { faqsCancellation } from 'data/faqs';

const FaqAltItem = ({ faq }) => {
  return (
    <>
      <h5 className="fs-0">{faq.title}</h5>
      <p className="fs--1">{faq.description}</p>
    </>
  );
};

export const FaqsPage = () => {
  const [faqsGeneralData] = useState(faqsGeneral);
  const [faqsPlansData] = useState(faqsPlans);
  const [faqsHistoricalLoadData] = useState(faqsHistoricalLoad);
  const [faqsFreeTrialData] = useState(faqsFreeTrial);
  const [faqsNotificationsData] = useState(faqsNotifications);
  const [faqsUsersAccessData] = useState(faqsUsersAccess);
  const [faqsDataQualityData] = useState(faqsDataQuality);
  const [faqsDataSyncData] = useState(faqsDataSync);
  const [faqsDataAgeData] = useState(faqsDataAge);
  const [faqsSecurityData] = useState(faqsSecurity);
  const [faqsTeamPerformanceData] = useState(faqsTeamPerformance);
  const [faqsCancellationData] = useState(faqsCancellation);

  return (
    <>
      <WidgetSectionTitle
        icon="question-circle"
        title="Frequently asked questions"
        subtitle=""
        transform="shrink-4"
        className="mb-4 mt-2"
      />
      <Card>
        <Card.Header>
          <h5 className="mb-0">Gitlights purpose</h5>
        </Card.Header>
        <Card.Body className="bg-light pb-0">
          <Row>
            <Col>
              {faqsGeneralData
                .slice(0, Math.floor(faqsGeneralData.length))
                .map(faq => (
                  <FaqAltItem key={faq.id} faq={faq} />
                ))}
            </Col>
          </Row>
        </Card.Body>
        <Card.Header>
          <h5 className="mb-0">Free trial</h5>
        </Card.Header>
        <Card.Body className="bg-light pb-0">
          <Row>
            <Col>
              {faqsFreeTrialData
                .slice(0, Math.floor(faqsFreeTrialData.length))
                .map(faq => (
                  <FaqAltItem key={faq.id} faq={faq} />
                ))}
            </Col>
          </Row>
        </Card.Body>
        <Card.Header>
          <h5 className="mb-0">Plans</h5>
        </Card.Header>
        <Card.Body className="bg-light pb-0">
          <Row>
            <Col>
              {faqsPlansData
                .slice(0, Math.floor(faqsPlansData.length))
                .map(faq => (
                  <FaqAltItem key={faq.id} faq={faq} />
                ))}
            </Col>
          </Row>
        </Card.Body>
        <Card.Header>
          <h5 className="mb-0">Historical Data Package</h5>
        </Card.Header>
        <Card.Body className="bg-light pb-0">
          <Row>
            <Col>
              {faqsHistoricalLoadData
                .slice(0, Math.floor(faqsHistoricalLoadData.length))
                .map(faq => (
                  <FaqAltItem key={faq.id} faq={faq} />
                ))}
            </Col>
          </Row>
        </Card.Body>
        <Card.Header>
          <h5 className="mb-0">
            Manage Subscription, Cancellation, and Account Deletion
          </h5>
        </Card.Header>
        <Card.Body className="bg-light pb-0">
          <Row>
            <Col>
              {faqsCancellationData
                .slice(0, Math.floor(faqsCancellationData.length))
                .map(faq => (
                  <FaqAltItem key={faq.id} faq={faq} />
                ))}
            </Col>
          </Row>
        </Card.Body>
        <Card.Header>
          <h5 className="mb-0">Notifications</h5>
        </Card.Header>
        <Card.Body className="bg-light pb-0">
          <Row>
            <Col>
              {faqsNotificationsData
                .slice(0, Math.floor(faqsNotificationsData.length))
                .map(faq => (
                  <FaqAltItem key={faq.id} faq={faq} />
                ))}
            </Col>
          </Row>
        </Card.Body>
        <Card.Header>
          <h5 className="mb-0">User Access and Permissions</h5>
        </Card.Header>
        <Card.Body className="bg-light pb-0">
          <Row>
            <Col>
              {faqsUsersAccessData
                .slice(0, Math.floor(faqsUsersAccessData.length))
                .map(faq => (
                  <FaqAltItem key={faq.id} faq={faq} />
                ))}
            </Col>
          </Row>
        </Card.Body>
        <Card.Header>
          <h5 className="mb-0">Data Quality and Source</h5>
        </Card.Header>
        <Card.Body className="bg-light pb-0">
          <Row>
            <Col>
              {faqsDataQualityData
                .slice(0, Math.floor(faqsDataQualityData.length))
                .map(faq => (
                  <FaqAltItem key={faq.id} faq={faq} />
                ))}
            </Col>
          </Row>
        </Card.Body>
        <Card.Header>
          <h5 className="mb-0">Update Frequency</h5>
        </Card.Header>
        <Card.Body className="bg-light pb-0">
          <Row>
            <Col>
              {faqsDataSyncData
                .slice(0, Math.floor(faqsDataSyncData.length))
                .map(faq => (
                  <FaqAltItem key={faq.id} faq={faq} />
                ))}
            </Col>
          </Row>
        </Card.Body>
        <Card.Header>
          <h5 className="mb-0">Data History and Retention</h5>
        </Card.Header>
        <Card.Body className="bg-light pb-0">
          <Row>
            <Col>
              {faqsDataAgeData
                .slice(0, Math.floor(faqsDataAgeData.length))
                .map(faq => (
                  <FaqAltItem key={faq.id} faq={faq} />
                ))}
            </Col>
          </Row>
        </Card.Body>
        <Card.Header>
          <h5 className="mb-0">Data Security</h5>
        </Card.Header>
        <Card.Body className="bg-light pb-0">
          <Row>
            <Col>
              {faqsSecurityData
                .slice(0, Math.floor(faqsSecurityData.length))
                .map(faq => (
                  <FaqAltItem key={faq.id} faq={faq} />
                ))}
            </Col>
          </Row>
        </Card.Body>
        <Card.Header>
          <h5 className="mb-0">
            How Gitlights Helps Improve Development Teams
          </h5>
        </Card.Header>
        <Card.Body className="bg-light pb-0">
          <Row>
            <Col>
              {faqsTeamPerformanceData
                .slice(0, Math.floor(faqsTeamPerformanceData.length))
                .map(faq => (
                  <FaqAltItem key={faq.id} faq={faq} />
                ))}
            </Col>
          </Row>
        </Card.Body>

        <Card.Footer className="py-3">
          <div className="text-center">
            <h6 className="fs-0 fw-normal">Have more questions?</h6>
            <p className="mb-0">
              Send us an email at{' '}
              <a href="mailto:support@gitlights.com" className="fw-bold">
                support@gitlights.com
              </a>
            </p>
          </div>
        </Card.Footer>
      </Card>
    </>
  );
};
FaqAltItem.propTypes = {
  faq: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  })
};
export default FaqsPage;
