import gitlightsLogo from 'assets/img/gitlights-logo.png';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';

export const SupportPage = () => {
  return (
    <>
      <WidgetSectionTitle
        icon="comments"
        title="Gitlights Support"
        subtitle="Need help? We're here for you!"
        transform="shrink-4"
        className="mb-4 mt-2"
      />
      <Row className="g-3 mb-3">
        <Col md={3}></Col>
        <Col md={6}>
          <Card className="text-center">
            <Card.Body className="p-5">
              <div className="display-1 text-300 fs-error join-gitlights-github-img">
                <Row className="g-3 mb-3">
                  <Col md={12}>
                    <img
                      width="150px"
                      height="160px"
                      src={gitlightsLogo}
                      alt="Gitlights Logo"
                    />
                  </Col>
                </Row>
              </div>
              <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
                Need Support?
              </p>
              <hr />
              <p>
                If you require assistance, please don't hesitate to contact us
                at:
              </p>
              <p className="lead mt-4 text-800 font-sans-serif fw-bold">
                <a href="mailto:support@gitlights.com">support@gitlights.com</a>
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}></Col>
      </Row>
      <Row>
        <Col md={1}></Col>
        <Col md={10}></Col>
        <Col md={1}></Col>
      </Row>
    </>
  );
};
export default SupportPage;
